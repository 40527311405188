const appointmentTranslation = {
  title: "Tu souhaites prendre un rendez-vous ?",
  desc: "Réserve-toi un bon moment dans le salon Merci l'Artiste! Par ici l'ami :",
  desc_link: "Réserve ton rendez-vous",
};

const pricesTranslation = {
  title: "Espèces uniquement",
  topFirstItem: "Coupe + Barbe",
  topFirstPrice: "40€",
  topSecondItem: "Décoloration + coupe",
  topSecondPrice: "90€",
  bottomFirstItem: "Coupe",
  bottomFirstPrice: "25€",
  bottomSecondItem: "Barbe",
  bottomSecondPrice: "25€",
};

export { appointmentTranslation, pricesTranslation };
