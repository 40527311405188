const footerTranslation = {
  street: "93 rue des Martyrs",
  weekDays: "Du mardi au vendredi",
  postalCode: "Champagne-sur-Oise 95660",
  workHours: "De 11h à 20h (samedi de 13h à 18h)",
  phoneNumber: "09 56 22 03 71",
  mailAddress: "merci.lartist@gmail.com",
  legalMentions: "Mentions légales",
};

export { footerTranslation };
