const navTranslation = {
  home: "Accueil",
  about: "A propos",
  booking: "Réservation",
  contact: "Contact",
  pricesCta: "Tarifs",
};

const bottomNavTranslation = {
  title: "Bienvenue l'ami",
  firstSubtitle:
    "Merci l’Artiste! est un univers atypique, un lieu où le client est écouté mais surtout compris. L’expertise et l’élégance sont les maîtres mots.",
  secondSubtitle:
    "Imaginez un endroit où vous pouvez retrouver l’ambiance des anciens salons privés et des social clubs du début du XXe siècle. Imaginez un lieu où l’art et le bien-être sont la priorité.",
  bookingCta: "Réserver en ligne >",
};

export { bottomNavTranslation, navTranslation };
